import React, { Component } from 'react';

// MultiLanguage
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';

import { Button, Container, Grid, TextField, InputAdornment, IconButton, } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';

import Footer from '../components/Footer';

import rootstyles from '../styles/Root';
import Logo from '../components/Logo';

// TODO
// History


class HomeView extends Component {
  state = {
    code: '',
    formfields: {
      code: {
        value: '',
        error: false,
        helperText: '',
      },
    },
  }

  handleChange = event => {
    this.setState({ code: event.target.value });

  }

  handleSubmit = event => {
    event.preventDefault();

    // Form validation
    if (this.state.code === '') {
      this.setState({
        formfields: {
          code: {
            ...this.state.formfields.code,
            error: true,
            helperText: 'required',
          }
        }
      });
      return
      
    }

    window.location = "/view/" + this.state.code;

  }

  render() {
    // styles from withStyles
    const { classes } = this.props;

    return (
      <div>
        <Container className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={false} sm={3} md={4} />
            <Grid item xs={12} sm={6} md={4}>
              <Logo />
              {/*Kessel*/}
              <br />
              <br />
              <FormattedHTMLMessage id="home.welcome"
                defaultMessage="Welcome! If you see this the language file is not loaded!"
                description="Welcome message on home page"
              />
              <br />
              <br />
              <Button
                variant="outlined"
                color="default"
                size="large"
                href="/new"
                fullWidth
                style={{ backgroundColor: 'white' }}
              ><FormattedMessage id='home.button.new' /></Button>
              <br />
              <br />

              <TextField
                id="code"
                name="code"
                //className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                //type={values.showPassword ? 'text' : 'password'}
                label="Access Code"
                helperText={this.state.formfields.code.helperText}
                value={this.state.code}
                onChange={this.handleChange}
                fullWidth
                style={{ backgroundColor: 'white' }}
                error={this.state.formfields.code.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="submit"
                        onClick={this.handleSubmit}
                        type="submit"
                      >
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br /><br /><br /><br />
              {/*Impressum, AGBs*/}
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default withStyles(rootstyles)(HomeView)