import React, { Component } from 'react';

import * as myLink from '../services/links';

import { IconButton, TextField, InputAdornment, } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

class WishListAddedItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let value = this.props.text;
    let helperText = "";
    // If Link extract infos
    if (myLink.isLink(this.props.text)) {
      if (myLink.extract(this.props.text).additionalText) {
        value = (myLink.extract(this.props.text).additionalText);
        helperText = (myLink.extract(this.props.text).url.hostname.replace('www.',''));
      }
    }

    return (
      <div key={this.props.code}>
        <div style={{ padding: "7px 2px" }}>
          <TextField
            id="wish"
            name="wish"
            disabled
            style={{width: "95%"}}
            value={value}
            helperText={helperText}
            onChange={this.props.onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="delete"
                    onClick={() => { this.props.onDelete(this.props.code) }}
                    disabled={this.props.ready}
                  >
                    <Delete />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    )
  }
}

export default (WishListAddedItem)