import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import WishListAddForm from './WishListAddForm';

import WishListAddedItem from './WishListAddedItem';

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishes: [],
      newwish: {
        name: '',
      },
    }
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  handleSumbit = event => {
    this.props.onAdd(this);
    this.setState({
      newwish: {
        name: '',
      }
    });
    this.props.onWishListInputChange("");
  }

  handleDelete = (code) => {
    this.props.onDelete(code);
  }

  handleChange = event => {
    this.setState({
      newwish: {
        name: event.target.value,
      }
    });
    this.props.onWishListInputChange(event.target.value);
  }

  render() {
    // If it is the first
    if (this.props.wishes === null) {
      if (!this.props.ready) {
        return (
          <div>
            <p><FormattedHTMLMessage id="wishlist.addwish" /></p>
            <WishListAddForm
              handleChange={this.handleChange}
              handleSumbit={this.handleSumbit}
              label={<FormattedMessage id="wishlist.addform.label" />}
              required />
          </div>
        )
      }

      return (<div></div>)

    }

    let wishList = this.props.wishes.map(wish => {
      return (
        <WishListAddedItem 
          key={wish.code}
          code={wish.code} 
          text={wish.text} 
          onChange={this.handleChange} 
          onDelete={this.handleDelete} 
          ready={this.props.ready} />
      )
    })

    return (
      <div>
        {!this.props.ready &&
          <div>
            <p><FormattedHTMLMessage id="wishlist.addwish" /></p>
            <WishListAddForm
              handleChange={this.handleChange}
              handleSumbit={this.handleSumbit}
              label={<FormattedMessage id="wishlist.addform.label" />}
              required />
          </div>
        }
        <div>
          {this.props.ready && <FormattedHTMLMessage id="wishlist.yourwishlist" />}
          {wishList}
        </div>
      </div>
    )
  }
}

export default (WishList)