import React, { Component } from 'react';

import imageFile from '../assets/logo.png';

import styles from './Logo.module.css';

const image = {
  src: imageFile,
  alt: 'WunschWichteln.de',
};

class Logo extends Component {
  render() {
    return (
      <img className={styles.img} src={image.src} alt={image.alt} />
    )
  }
}

export default (Logo)