import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { TextField } from '@material-ui/core';

import dateFormat from 'dateformat';

import styles from './DrawTime.module.css';

class DrawTime extends Component {
  render() {
    let date = dateFormat(this.props.date, "dddd, dd.mm.yyyy HH:MM:ss")
    let text = <FormattedHTMLMessage id="drawtime.member.text" values={{date: date}} />

    if (this.props.onChange) {
      return (
        <div className={styles.container}>
          <div className={styles.datechanger}>
            <TextField
              id="draw_time"
              name="draw_time"
              type="datetime-local"
              onChange={this.props.onChange}
              value={this.props.date}
              className={styles.datechangertextfield}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.container}>
        <div className={styles.date}>
          {text}
        </div>
      </div>
    )
  }
}

export default (DrawTime)