import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import ButtonAmazonSearch from './ButtonAmazonSearch';
import ButtonAmazonProduct from './ButtonAmazonProduct';

class WishListShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishes: [],
    }
  }

  render() {
    // Show Ideas in case the member did not add any wishes
    if (this.props.wishes === null) {
      return (
        <div>
          <FormattedHTMLMessage id="wishlistshow.nowish" />
          <ButtonAmazonSearch giftidea />
        </div>
      )
    }

    let wishList = this.props.wishes.map(wish => {
      // Detect Links
      if (RegExp('https://.*|http://.*').test(wish.text)) {
        var urlregex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Zöäüß0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Zöäüß0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Zöäüß0-9+&@#\/%=~_|$?!:,.]*\)|[A-Zöäüß0-9+&@#\/%=~_|$])/igm;
        var url = new URL(wish.text.match(urlregex));
        var text = wish.text.replace(urlregex, '');

        // Amazon?
        if (url.host === 'amazon.de' || url.host === 'www.amazon.de') {
          var asinregex = /(?:[/dp/]|$)([A-Z0-9]{10})/g;
          var asin = url.href.match(asinregex);

          if (asin) {
            asin = asin[0].replace('/', '');
            return (
              <div style={{ padding: "10px 0" }} key={wish.code}>
                <span>{text ? text : asin}</span>
                <span><ButtonAmazonProduct asin={asin} /></span>
              </div>
            )
          }

          return (
            <div style={{ padding: "10px 0" }} key={wish.code}>
              <span>{text}</span>
              <span><ButtonAmazonSearch keywords={text} /></span>
            </div>
          )
        }

        // Non Amazon Link
        return (
          <div style={{ padding: "10px 0" }} key={wish.code}>
            <span>{text}</span>
            <span><a target="_blank" href={url}>{url.host}</a></span>
          </div>
        )
      }

      // Default Show Amazon Search Button if there is no link
      return (
        <div style={{ padding: "10px 0" }} key={wish.code}>
          <span>{wish.text}</span>
          <span><ButtonAmazonSearch keywords={wish.text} /></span>
        </div>
      )
    })

    return (
      <div>
        <FormattedHTMLMessage id="wishlistshow.wish" />
        {wishList}
      </div>
    )
  }
}

export default (WishListShow)