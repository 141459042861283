import React, { Component } from 'react';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import API from '../axios';

import { Button, Container, Grid, FormGroup, TextField } from '@material-ui/core';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import { withStyles } from '@material-ui/core/styles';

import rootstyles from '../styles/Root';

class NewView extends Component {
  constructor() {
    super();
    this.state = {
      group: {
        title: '',
        description: '',
        price: '',
        /*pin: '',
        email: '',
        type: '',*/
        draw_time: '',
      },
      formfields: {
        title: {
          value: '',
          error: false,
          helperText: '',
        },
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleInputChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var newState = Object.assign(this.state, {
      group: Object.assign(this.state.group, { [name]: value, }),
    });

    this.setState(newState);

  }

  handleSubmit = e => {
    e.preventDefault();

    // Form validation
    if (this.state.group.title === "") {
      this.setState({
        formfields: {
          title: {
            ...this.state.formfields.title,
            error: true,
            helperText: 'missing',
          }
        }
      });
      return;

    }

    // Send data
    API.post('/group', this.state.group)
      .then(res => {
        if (res.status === 201 && res.data.code) {
          window.location = "/view/" + res.data.code;
          return
        }

      }).catch(err => {
        //console.error(err);
        this.handleSnackbar("Creating group failed");

      })

  }

  handleSnackbar = (message) => {
    this.refs.snackbar.handleOpen(message);

  }

  render() {
    // styles from withStyles
    const { classes } = this.props;

    return (
      <Container className={classes.root}>
        <Grid container>
          <Grid item xs={false} sm={3} md={4} />
          <Grid item xs={12} sm={6} md={4}>
            <h2><FormattedHTMLMessage id="new.title" /></h2>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <TextField
                  id="title"
                  name="title"
                  label={<FormattedMessage id="new.form.title.label" />}
                  //variant="outlined" 
                  margin="normal"
                  defaultValue={this.state.title}
                  onChange={this.handleInputChange}
                  error={this.state.formfields.title.error}
                  helperText={this.state.formfields.title.helperText}
                />

                <TextField
                  id="desciption"
                  name="description"
                  label={<FormattedMessage id="new.form.description.label" />}
                  margin="normal"
                  defaultValue={this.state.desciption}
                  onChange={this.handleInputChange}
                />

                <TextField
                  id="price"
                  name="price"
                  label={<FormattedMessage id="new.form.price.label" />}
                  margin="normal"
                  defaultValue={this.state.price}
                  onChange={this.handleInputChange}
                />

                <TextField
                  id="draw_time"
                  name="draw_time"
                  label={<FormattedMessage id="new.form.drawtime.label" />}
                  margin="normal"
                  type="datetime-local"
                  //defaultValue= //tomorrow
                  onChange={this.handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {/*<TextField
                                    id="email"
                                    name="email"
                                    label="eMail"
                                    helperText="Only used to sent you the access code"
                                    margin="normal"
                                    defaultValue={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                                <br />
                                Protection Code (PIN)*/}
                <br />
                <br />
                <Button
                  variant="outlined"
                  color="default"
                  size="large"
                  fullWidth
                  type="submit"
                ><FormattedMessage id='new.button.submit'/></Button>
              </FormGroup>
            </form>
          </Grid>
          <CustomizedSnackbars ref="snackbar" variant="error" />
        </Grid>
      </Container>
    )
  }
}

export default withStyles(rootstyles)(NewView)
