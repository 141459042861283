import React, { Component } from 'react';

import { TextField, InputAdornment, IconButton, CardContent, CardActions, } from '@material-ui/core';
import { ArrowForward, Delete, Visibility, VisibilityOff, MoreVert, ThumbDown, ThumbUp } from '@material-ui/icons';
import { Card, CardHeader, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ButtonWhatsApp from './ButtonWhatsApp';

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 300,
    margin: 7,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#002a37',
  },
}));

export default function MemberListCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [showpartner, setShowpartner] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowpartnerClick = () => {
    setShowpartner(!showpartner);
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.member.name[0]}{props.member.name[1]}
          </Avatar>
        }
        /*action={
          <IconButton aria-label="settings">
            <MoreVert />
          </IconButton>
        }*/
        title={props.member.name}
        subheader={props.member.code}
      />
      <CardActions>
        {props.member.ready ? //State
          <IconButton><ThumbUp style={{color: "green"}} /></IconButton>
          : 
          <IconButton><ThumbDown style={{color: "orange"}} /></IconButton>}

        <ButtonWhatsApp code={props.member.code} />

        {!props.drawn && //Delete
          <IconButton aria-label="delete" onClick={() => {props.handleDelete(props.member.code)}}><Delete /></IconButton>}

        {props.drawn ? 
          <IconButton aria-label="show partner" onClick={handleShowpartnerClick}>
            {showpartner ?
              <VisibilityOff /> : <Visibility />}
          </IconButton>
          : ""}

      </CardActions>
      <CardContent>
        {showpartner &&
          <div>{props.member.partner.name}</div>}
      </CardContent>
    </Card>
  )
}
