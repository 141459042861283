import React, { Component } from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import API from '../axios';

import GroupHeadline from '../components/GroupHeadline';
import Footer from '../components/Footer';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import WishList from '../components/WishList';
import WishListShow from '../components/WishListShow';

import { Container, Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import rootstyles from '../styles/Root';
import Confirm from '../components/DialogConfirm';
import DrawTime from '../components/DrawTime';


class ViewMember extends Component {
  state = {
    member: {
      code: '',
      name: '',
      group: {
        code: '',
        title: '',
        description: '',
        draw_time: '',
        drawn: false,
        drawn_open: false,
      },
      wishes: [],
      ready: false,
      partner: {
        name: '',
        wishes: [],
      },
      state: '',
    },
    formInput: '',
    }

  onWishListInputChange = (value) => {
    this.setState({formInput: value});
  }

  getMember = () => {
    //console.log(this.props.match.params.code);
    API.get('/member/' + this.props.code)
      .then(res => {
        this.setState({ member: res.data, state: 'loaded' });

        //console.log(group);
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Error loading code");
      });
  }

  addWish = (e) => {
    var body = {
      member: this.state.member.code,
      text: e.state.newwish.name,
    };

    API.post('/wish', body)
      .then(res => {
        this.getMember();
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Unable to add wish");
      });
  }

  deleteWish = (code) => {
    API.delete('/wish/' + code)
      .then(res => {
        this.getMember();
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Unable to delete wish");
      });
  }

  modifyMember = event => {
    if (this.state.formInput.length !== 0) {
      this.setState({ state: 'error' });
      this.refs.snackbar.handleOpen(<FormattedMessage id="member.readybutton.err.unsavedwish" />);
      return
    }

    const target = event.currentTarget;
    const value = target.type === 'checkbox' ? target.checked : JSON.parse(target.value); // JSON.parse converts string to boolean
    const name = target.name;

    var body = {
      code: this.state.member.group.code,
    };
    body[name] = value;

    API.put('/member/' + this.props.code, body)
      .then(res => {
        var newState = Object.assign(this.state, {
          member: Object.assign(this.state.member, { [name]: value, }),
        });
    
        this.setState(newState);
    
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Error while editing");
      });
  }

  componentDidMount() {
    this.getMember()
  }

  render() {
    // styles from withStyles and translations from injectIntl
    const { classes, intl } = this.props;

    return (
      <div>
        <GroupHeadline 
          title={this.state.member.group.title} 
          description={this.state.member.group.description}
          price={this.state.member.group.price} />

        {this.state.member.group.draw_time &&
          <DrawTime
            date={this.state.member.group.draw_time } />
        }

        <Container className={classes.root}>
          <Grid container>
            <Grid item xs={false} md={3} />
            <Grid item xs={12} md={6}>
              <h2><FormattedHTMLMessage id="member.hello" values={{name: this.state.member.name}} /></h2>
              
              {!this.state.member.group.drawn && !this.state.member.group.drawn_open && !this.state.member.ready &&
                // Add wishes and set to finish 
                <div>
                  <WishList 
                    wishes={this.state.member.wishes} 
                    onAdd={this.addWish} 
                    onDelete={this.deleteWish} 
                    ready={this.state.member.ready} 
                    onWishListInputChange={this.onWishListInputChange} />
                  
                  <br />
                  <Confirm 
                    title={<FormattedMessage id="member.readymodal.confirm.title" />}
                    description={<FormattedMessage id="member.readymodal.confirm.text" /> }
                    disagree={<FormattedMessage id="member.readymodal.confirm.no" /> }
                    agree={<FormattedMessage id="member.readymodal.confirm.yes" /> }
                  >
                    {confirm => (
                      <Button
                        variant="outlined"
                        name="ready"
                        disabled={this.state.member.ready}
                        //color={this.state.member.ready ? 'primary' : 'default'}
                        style={{color: "#002a37"}}
                        onClick={confirm(this.modifyMember)}
                        value={!this.state.member.ready}
                        size="medium"
                      >{ intl.formatMessage({ id: 'member.readybutton.label'}) }</Button>
                      )}
                  </Confirm>

                  {/*<FormControlLabel
                    style={{ padding: "10px 0", }}
                    control={
                      <Checkbox
                        name="ready"
                        checked={this.state.member.ready}
                        onChange={this.modifyMember}
                        style={{color: "#002a37"}}
                      />
                    }
                    label= { intl.formatMessage({ id: 'member.readybutton.label' }) }
                  />*/}
                </div>}

              {this.state.member.ready && !this.state.member.group.drawn &&
                <p>
                  <FormattedHTMLMessage id="member.ready.info" />
                </p>}

              {this.state.member.group.drawn_open &&
                <div>
                  <WishList wishes={this.state.member.wishes} onAdd={this.addWish} onDelete={this.deleteWish} />
                </div>}

              {this.state.member.group.drawn &&
                // Show Partner 
                <div>
                  <FormattedHTMLMessage id="member.partner" values={{name: this.state.member.partner.name}} />
                  <WishListShow wishes={this.state.member.partner.wishes} />
                </div>}
              <br />

              {/*<Button
                variant="contained"
                name="ready"
                color={this.state.member.ready ? 'primary' : 'default'}
                onClick={this.modifyMember}
                size="large"
              >I am ready</Button>*/}

              </Grid>
            </Grid>
          </Container>
        <Footer amazondisclaimer />
        <CustomizedSnackbars ref="snackbar" variant="error" />
      </div>
    )
  }
}


export default injectIntl(withStyles(rootstyles)(ViewMember))