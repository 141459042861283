export const isLink = (text) => {
    if (RegExp('https://.*|http://.*').test(text)) {
        return true
    }

    return false
}

export const extract = (text) => {
    var urlregex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Zöäüß0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Zöäüß0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Zöäüß0-9+&@#\/%=~_|$?!:,.]*\)|[A-Zöäüß0-9+&@#\/%=~_|$])/igm;
    var url = new URL(text.match(urlregex));
    var additionalText = text.replace(urlregex, '');

    return {"url": url, "additionalText": additionalText}
}
