import React, { Component } from 'react';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import API from '../axios';

import { Button, Container, Grid, IconButton } from '@material-ui/core';
import { AssignmentReturn } from '@material-ui/icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import MemberList from '../components/MemberList';
import GroupHeadline from '../components/GroupHeadline';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import ModalRegCode from '../components/ModalRegCode';
import { withStyles } from '@material-ui/core/styles';

import rootstyles from '../styles/Root';
import Confirm from '../components/DialogConfirm';
import DrawTime from '../components/DrawTime';
import ButtonWhatsAppDrawn from '../components/ButtonWhatsAppDrawn';


class ViewGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {
        title: '',
        description: '',
        price: '',
        members: [],
        drawn: false,
        draw_time: '',
      },
      state: '',
      firstview: true,
    }

    this.modifyGroup = this.modifyGroup.bind(this);
  }
  

  getGroup = () => {
    //console.log(this.props.match.params.code);
    API.get('/group/' + this.props.code)
      .then(res => {
        const group = res.data;
        this.setState({ group: group, state: 'loaded' });

        //console.log(group);
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Error loading code");
      });
  }

  addMember = e => {
    //e.preventDefault();
    //console.log(e);
    var body = {
      group: this.state.group.code,
      name: e.state.newmember.name,
    };

    API.post('/member', body)
      .then(res => {
        this.getGroup();

      }).catch(err => {
        this.setState({ state: 'error' + err });
        this.refs.snackbar.handleOpen("Error loading code" + err);
      });
  }

  deleteMember = code => {
    API.delete('/member/' + code)
      .then(res => {
        this.getGroup();

      }).catch(err => {
        this.setState({ state: 'error' + err });
        this.refs.snackbar.handleOpen("Error deleting member" + err);
      });
  }

  /*toggleGroupDrawnState = () => {
    var newState = Object.assign(this.state, {
      group: Object.assign(this.state.group, { [drawn]: !this.state.group.drawn, }),
    });
    this.setState(newState);
  }*/

  modifyGroup = event => {
    const target = event.currentTarget;
    const value = target.type === 'checkbox' ? 
      target.checked
      :
      target.value === "true" || target.value === "false" ?
        JSON.parse(target.value) // JSON.parse converts string to boolean
        :
        target.value; 
    const name = target.name;

    var body = {};
    body[name] = value;

    API.put('/group/' + this.props.code, body)
      .then(res => {
        var newState = Object.assign(this.state, {
          group: Object.assign(this.state.group, { [name]: value, }),
        });
        this.setState(newState);    
        this.getGroup();

      }).catch(err => {
        this.setState({ state: 'error' + err });
        this.refs.snackbar.handleOpen("Error loading code" + err);

      });
  }

  handleModal = () => {
    this.refs.qrcode.handleOpen();
  }

  componentDidMount() {
    this.getGroup();
  }

  render() {
    // styles from withStyles
    const { classes } = this.props;

    let welcomeMsg =
      <div>
        <p><FormattedHTMLMessage id="group.welcome" /></p>

        <p><FormattedHTMLMessage id="group.accesscode" values={{code: this.state.group.code}}/>
          <CopyToClipboard text={this.state.group.code}>
            <IconButton aria-label="copy to clipboard" size="small">
              <AssignmentReturn />
            </IconButton>
          </CopyToClipboard></p>

      </div>;


    return (
      <div>
        <GroupHeadline
          title={this.state.group.title}
          description={this.state.group.description}
          price={this.state.group.price} 
          code={this.state.group.code} />

        <DrawTime 
          date={this.state.group.draw_time} onChange={this.modifyGroup} />

        <Container className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={false} md={3} />
            <Grid item xs={12} md={6}>
              {this.state.group.members === null && welcomeMsg}
              {!this.state.group.drawn ? 
                <div><FormattedHTMLMessage id="group.addmember" /><br />
                <Button variant="outlined" onClick={this.handleModal}>Self registration QRCode</Button>
                
                <ModalRegCode 
                  ref="qrcode" 
                  link={process.env.REACT_APP_BASE_URL + "/view/" + this.state.group.registercode} />

                <FormattedHTMLMessage id="group.addmemberor" /></div> : ""}
              
              <MemberList
                members={this.state.group.members} 
                onAdd={this.addMember} 
                onDelete={this.deleteMember} 
                drawn={this.state.group.drawn} />

              <br />
              {this.state.group.members !== null && 
                <div>
                  {!this.state.group.drawn &&
                    <p><FormattedHTMLMessage id="group.readyquestion" /></p> }
                  
                  {this.state.group.drawn &&
                    <p>
                      <FormattedHTMLMessage id="group.readyinfo" />
                      <ButtonWhatsAppDrawn />
                      <FormattedHTMLMessage id="group.readyinfo.part2" />
                    </p>}

                  <Confirm 
                    title={this.state.group.drawn ? <FormattedMessage id="group.readymodal.warn.title" /> : <FormattedMessage id="group.readymodal.confirm.title" />}
                    description={this.state.group.drawn ? <FormattedMessage id="group.readymodal.warn.text" /> : <FormattedMessage id="group.readymodal.confirm.text" /> }
                    disagree={this.state.group.drawn ?  <FormattedMessage id="group.readymodal.warn.no" /> :  <FormattedMessage id="group.readymodal.confirm.no" /> }
                    agree={this.state.group.drawn ?  <FormattedMessage id="group.readymodal.warn.yes" /> :  <FormattedMessage id="group.readymodal.confirm.yes" /> }
                  >
                    {confirm => (
                      <Button
                        variant="outlined"
                        name="drawn"
                        //color={this.state.group.drawn ? 'primary' : 'default'}
                        style={{color: "#002a37"}}
                        onClick={confirm(this.modifyGroup)}
                        value={!this.state.group.drawn}
                        size="medium"
                      >{this.state.group.drawn ? "Reset" : "Let's Wichtel"}</Button>
                    )}
                  </Confirm>
                </div>}
            </Grid>
          </Grid>
          <CustomizedSnackbars ref="snackbar" variant="error" />
        </Container>
      </div>

    )
  }
}

export default withStyles(rootstyles)(ViewGroup)