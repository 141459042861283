import React, { Component } from 'react';

import { TextField, InputAdornment, IconButton, } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

class WishListAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formfields: {
        text: {
          value: '',
          error: false,
          helperText: '',
        },
      },
    }
    //this.handleSumbit = this.handleSumbit.bind(this);
    //this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    this.setState({
      formfields: {
        text: {
          ...this.state.formfields.text,
          value: e.target.value,
        }
      }
    });
    this.props.handleChange(e);
  }

  handleSumbit = e => {
    e.preventDefault();
    
    // Form validation
    if (this.props.required && this.state.formfields.text.value === "") {
      this.setState({
        formfields: {
          text: {
            ...this.state.formfields.text,
            error: true,
            helperText: 'required',
          }
        }
      });
      return;

    }

    this.props.handleSumbit(e);

    // Reset from value.
    // This runs inside this class so if an error occure during post request the value is lost, anyways.
    this.setState({
      formfields: {
        text: {
          ...this.state.formfields.text,
          value: '',
          error: false,
          helperText: '',
        }
      }
    });

  }

  render() {
    return (
      <div style={{padding: "7px 7px"}}>
        {/*<Fab style={{color: "#002a37"}} size="large">A</Fab>*/}
        <TextField
          id="text"
          name="text"
          variant="outlined"
          style={{color: "red", width: "100%"}}
          label={this.props.label}
          onChange={this.handleChange}
          value={this.state.formfields.text.value}
          error={this.state.formfields.text.error}
          helperText={this.state.formfields.text.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="submit"
                  onClick={this.handleSumbit}
                  type="submit"
                >
                  <ArrowForward />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    )
  }
}

export default (WishListAddForm)