import React, { Component } from 'react';

import styles from './Footer.module.css';

class Footer extends Component {
  render() {
    return (
      <footer className={styles.footer}>
        <hr style={{height: '1px', backgroundColor: '#191919', width: '80vw'}} />
        {this.props.amazondisclaimer && 
          <div className={styles.amazondisclaimer}>** Amazon Partnerlink - Als Amazon-Partner verdiene ich an qualifizierten Verkäufen.</div>}
        <div>
          <br />We are Cookie FREE!!
        </div>
      </footer>
    )
  }
}

export default (Footer)