import React, { Component } from 'react';

import MemberListCard from './MemberListCard';

import { TextField, InputAdornment, IconButton, } from '@material-ui/core';
import { ArrowForward, Delete, CheckBox, Announcement, Visibility, VisibilityOff } from '@material-ui/icons';

class MemberList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      newmember: {
        name: '',
      },
      formfields: {
        name: {
          value: '',
          error: false,
          helperText: '',
        },
      },
      showpartner: {
        show: false,
        selected: '',
      },
    }
    this.handleSumbit = this.handleSumbit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  handleSumbit = event => {
    // Form validation
    if (this.state.newmember.name === "") {
      this.setState({
        formfields: {
          name: {
            ...this.state.formfields.name,
            error: true,
            helperText: '',
          }
        }
      });
      return;

    }

    this.props.onAdd(this);
    this.setState({
      newmember: {
        name: '',
      },
      formfields: {
        name: {
          ...this.state.formfields.name,
          error: false,
          helperText: '',
        }
      }
    });
  }

  handleChange = event => {
    this.setState({
      newmember: {
        name: event.target.value,
      }
    });
  }

  handleDelete = code => {
    this.props.onDelete(code);
  }

  togglePartner = code => {
    this.setState({ showpartner: {show: !this.state.showpartner.show, selected: code, }});
  }

  render() {
    let addForm = <div>
      <TextField
        id="name"
        name="name"
        variant="outlined"
        label="Name"
        style={{width: "90%"}}
        onChange={this.handleChange}
        value={this.state.newmember.name}
        error={this.state.formfields.name.error}
        helperText={this.state.formfields.name.helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="submit"
                onClick={this.handleSumbit}
                type="submit"
              >
                <ArrowForward />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>;

    // If it is the first member
    if (this.props.members === null) {
      return (
        addForm
      )
    }

    let memberList = this.props.members.map((member, index) => {
      return (
        <MemberListCard 
          key={member.code} 
          member={member} 
          handleDelete={this.handleDelete} 
          drawn={this.props.drawn} />
      )
    })

    return (
      <div>
        {!this.props.drawn &&
          addForm}
        <br />
        {memberList}
      </div>
    )
  }
}

export default (MemberList)