import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { useIntl } from "react-intl";

import { IconButton } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';



export default function ButtonWhatsAppDrawn(props) {
  const intl = useIntl()

  let ln = process.env.REACT_APP_BASE_URL + "/view/" + props.code
  let text = intl.formatMessage({ id: "notify.drawn.whatsapp.text" })
  let uri = "https://wa.me/?text=" + text;
  let link = encodeURI(uri);

  return (
    <IconButton
      href={link}
      target="_blank">
        <WhatsApp />
    </IconButton>
  )
}