import React, { Component } from 'react';

import QRCode from 'qrcode.react';

import { Modal } from '@material-ui/core';

import styles from './ModalRegCode.module.css';

class ModalRegCode extends Component {
  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={styles.paper}>
            <QRCode value={this.props.link} size={window.innerWidth <= window.innerHeight ? window.innerWidth/2 : window.innerHeight/2} />
          </div>
        </Modal>
      </div>
    )
  }
}

export default (ModalRegCode);