import React, { Component } from 'react';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import API from '../axios';

import GroupHeadline from '../components/GroupHeadline';
import CustomizedSnackbars from '../components/CustomizedSnackbars';

import { Button, Container, Grid, TextField, FormGroup, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AssignmentReturn } from '@material-ui/icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import rootstyles from '../styles/Root';
import styles from './ViewRegister.module.css';

class ViewRegister extends Component {
  state = {
    group: {
      title: '',
      description: '',
      members: [],
    },
    formfields: {
      name: {
        value: '',
        error: false,
        helperText: '',
      },
    },
    newmember: {
      name: '',
      code: '',
      created: false
    },
    state: '',
  }

  getGroup = () => {
    //console.log(this.props.match.params.code);
    API.get('/group/' + this.props.code)
      .then(res => {
        const group = res.data;
        this.setState({ group: group, state: 'loaded' });

        //console.log(group);
      }).catch(err => {
        this.setState({ state: 'error' });
        this.refs.snackbar.handleOpen("Error loading code");
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (this.state.newmember.name === "") {
      this.setState({
        formfields: {
          name: {
            ...this.state.formfields.name,
            error: true,
            helperText: 'required',
          }
        }
      });
      return;

    }

    this.addMember(e);
  }

  handleGotIt = (e) => {
    e.preventDefault();

    window.location = "/view/" + this.state.newmember.code;
  }

  handleChange = event => {
    this.setState({
      newmember: {
        name: event.target.value,
        code: '',
        created: false
      }
    });
  }


  addMember = e => {
    e.preventDefault();
    //console.log(e);
    var body = {
      group: this.state.group.registercode,
      name: this.state.newmember.name,
    };

    API.post('/member', body)
      .then(res => {
        if (res.status === 201 && res.data.code) {
          this.setState({
            newmember: {
              name: this.state.newmember.name,
              code: res.data.code,
              created: true,
            }
          });
          return
        }

      }).catch(err => {
        this.setState({ state: 'error' + err });
        this.refs.snackbar.handleOpen("Error loading code" + err);
      });
  }

  componentDidMount() {
    this.getGroup();
  }

  render() {
    return (
      <div>
        <GroupHeadline
          title={this.state.group.title}
          description={this.state.group.description}
          price={this.state.group.price} />

        <Container className={styles.form}>
          <Grid container alignItems="center">
            <Grid item xs={false} sm={3} md={4} />
            <Grid item xs={12} sm={6} md={4}>
              <FormattedHTMLMessage id="register.welcome" />
              <form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <TextField
                    id="name"
                    name="name"
                    label="Your name"
                    margin="normal"
                    onChange={this.handleChange}
                    error={this.state.formfields.name.error}
                    helperText={this.state.formfields.name.helperText}
                    disabled={this.state.newmember.created}
                  />
                  <br />
                  <br />
                  <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    type="submit"
                    disabled={this.state.newmember.created}
                  >Submit</Button>
                </FormGroup>
              </form>
              { this.state.newmember.created &&
                <div>
                  <br />
                  <FormattedHTMLMessage id="register.accesscode" values={{code: this.state.newmember.code}} />
                  <CopyToClipboard text={this.state.newmember.code}>
                    <IconButton aria-label="copy to clipboard" size="small">
                      <AssignmentReturn />
                    </IconButton>
                  </CopyToClipboard>
                  <form onSubmit={this.handleGotIt}>
                    <br />
                    <Button
                      variant="outlined"
                      color="default"
                      size="large"
                      type="submit"
                    ><FormattedMessage id="register.button.gotit" /></Button>
                  </form>
              </div>}
            </Grid>
          </Grid>
          <CustomizedSnackbars ref="snackbar" variant="error" />
        </Container>
      </div>

    )
  }
}

export default withStyles(rootstyles)(ViewRegister)