const rootstyles = {
    root: {
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //borderRadius: 3,
        //border: 0,
        //color: 'white',
        minHeight: '80vh',
        padding: '15px 5px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
};

export default (rootstyles);