import React, { Component } from 'react';

import { IconButton, } from '@material-ui/core';
import { ShoppingCart, } from '@material-ui/icons';

function buildlink(partnerid, type, asin) {
  let link = "https://www.amazon.de"

  switch (type) {
    case 'product': { 
      if (asin) {
        link = link + "/gp/product"
        link += "/" + asin
        link = link + "/ref=as_li_tl"
        //link = link + "?index=" + category + "&keywords=" + keywords
        //https://www.amazon.de/gp///?&camp=1638&creative=6742&creativeASIN=B07TC2BK1X&linkCode=as2&tag=dom04-21&linkId=3022bd35296f4afc3a8f8f3479e3f620
        break

      }

      break

    }
    default: { 
    
    }
  }

  // Add tag
  //// if it is not the first option
  /.*\?.*/.test(link) ? link = link + "&" : link = link + "?"
  link = link + "tag=" + partnerid // "&linkCode=as2" // "&camp=1638&creative=6742"
  
  var url = new URL(link);
  return url.href

}


class ButtonAmazonProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleSumbit = this.handleSumbit.bind(this);
  }

  handleSumbit = event => {
    this.props.onClick(this);
  }

  render() {
    if (this.props.asin) {
      return (
        <IconButton
          aria-label="amazon.de gift idea"
          //onClick={this.handleSubmit}
          href={buildlink(process.env.REACT_APP_AMZN_PARTNERID_PRODUCT, "product", this.props.asin)}
          target="_blank"
          size="small"
          >
          <ShoppingCart />Amazon.de **
        </IconButton>
      )
    }

    return (
      <div></div>
    )
  }
}

export default (ButtonAmazonProduct)