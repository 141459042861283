import React, { Component } from 'react';

import { IconButton, } from '@material-ui/core';
import { Search, } from '@material-ui/icons';

function buildlink(partnerid, type, category, keywords) {
  let link = "https://www.amazon.de"

  switch (type) {
    case 'search': { 
      if (category && keywords) {
        link = link + "/gp/search"
        link = link + "?index=" + category + "&keywords=" + keywords
        break

      }

      //link = link
      break

    }
    case 'giftidea': {
      link = link + "/weihnachten/ref=cg_XMAS19_1a1_w"
      if (category === "Secret-Santa") { link = link + "?categoryId=Secret-Santa" }
      break

    }
    default: { 
      //link = link
    
    }
  }

  // Add tag
  //// if it is not the first option
  /.*\?.*/.test(link) ? link = link + "&" : link = link + "?"
  link = link + "tag=" + partnerid // "&linkCode=ur2" // "&camp=1638&creative=6742"
  
  var url = new URL(link);
  return url.href

}


class ButtonAmazonSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleSumbit = this.handleSumbit.bind(this);
  }

  handleSumbit = event => {
    this.props.onClick(this);
  }

  render() {
    var partnerid = process.env.REACT_APP_AMZN_PARTNERID

    if (this.props.giftidea) {
      if (process.env.REACT_APP_AMZN_PARTNERID_GIFTIDEA) {
        partnerid = process.env.REACT_APP_AMZN_PARTNERID_GIFTIDEA
      }

      return (
        <IconButton
          aria-label="amazon.de gift idea"
          onClick={this.handleSubmit}
          href={buildlink(partnerid, "giftidea", "Secret-Santa", "")}
          target="_blank"
          size="small"
          >
          <Search />Amazon.de **
        </IconButton>
      )
    }

    if (process.env.REACT_APP_AMZN_PARTNERID_SEARCH) {
      partnerid = process.env.REACT_APP_AMZN_PARTNERID_SEARCH
    }

    return (
      <IconButton
        aria-label="search with amazon.de"
        onClick={this.handleSubmit}
        href={buildlink(partnerid, "search", "aps", this.props.keywords)}
        target="_blank"
        size="small"
        >
        <Search />Amazon.de **
      </IconButton>
    )
  }
}

export default (ButtonAmazonSearch)