//https://itnext.io/add-confirmation-dialog-to-react-events-f50a40d9a30d
import React, { Component } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from '@material-ui/core';

export default class DialogConfirm extends Component {
  state = {
    open: false,
    callback: null
  }

  show = callback => event => {
    event.preventDefault()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value }
    }

    this.setState({
      open: true,
      callback: () => callback(event)
    })
  }

  hide = () => this.setState({ open: false, callback: null })

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children(this.show)}

        <Dialog 
          open={this.state.open} 
          onClose={this.hide}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title" style={{color: "white", background: "#002a37"}}>{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              {this.props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hide}>
              {this.props.disagree}
            </Button>
            <Button onClick={this.confirm} style={{color: "white", background: "#002a37"}} autoFocus>
              {this.props.agree}
            </Button>
          </DialogActions>
        </Dialog>
        
      </React.Fragment>
    )
  }
}
