import React, { Component } from 'react';

import styles from './GroupHeadline.module.css';

class GroupHeadline extends Component {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.description}>{this.props.description}</div>
        <div className={styles.price}>{this.props.price}</div>
        <div className={styles.code}>{this.props.code}</div>
      </header>
    )
  }
}

export default (GroupHeadline)