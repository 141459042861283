import React, { Component } from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import API from '../axios';

import ViewGroup from './ViewGroup';
import ViewMember from './ViewMember';
import ViewRegister from './ViewRegister';

// MaterialUi
import { CircularProgress, TextField, InputAdornment, IconButton, } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

class ViewView extends Component {
  state = {
    code: '',
    codetype: '', //group, member, register
    state: 'loading',
    formfields: {
      code: {
        value: '',
        error: false,
        helperText: '',
      },
    },
  }

  getCodeType = () => {
    //console.log(this.props.match.params.code);
    API.get('/code/' + this.props.match.params.code)
      .then(res => {
        //const group = res.data;
        this.setState({ codetype: res.data.kind, state: 'loaded' });
        //this.refs.snackbar.handleOpen("Loaded","success");

        //console.log(group);
      }).catch(err => {
        this.setState({ state: 'error' });
        //this.refs.snackbar.handleOpen("Error loading code");
      });
  }

  componentDidMount() {
    //this.setState({ state: 'loading' });
    this.getCodeType();
  }

  handleChange = event => {
    this.setState({ code: event.target.value });

  }

  handleSubmit = event => {
    event.preventDefault();

    // Form validation
    if (this.state.code === '') {
      this.setState({
        formfields: {
          code: {
            ...this.state.formfields.code,
            error: true,
            helperText: 'required',
          }
        }
      });
      return
      
    }

    window.location = "/view/" + this.state.code;

  }


  render() {
    if (this.state.state === 'loading') {
      return (<div
        //className={styles.spinner}
        style={{
          height: "100vh",
          //background: 'linear-gradient(-45deg, #fff 5%, #002a37 30%)',
        }}
        >
          <div style={{ padding: "50vh 0", }}>
            <CircularProgress
            color='primary' />
          </div>
        </div>)
    }

    switch (this.state.codetype) {
      case 'group':
        return (<ViewGroup code={this.props.match.params.code} />)
      case 'register':
        return (<ViewRegister code={this.props.match.params.code} />)
      case 'member':
        return (<ViewMember code={this.props.match.params.code} />)
      default:
        return (
          <div style={{ padding: "40vh 0", }}>
            <FormattedHTMLMessage id="error.notfound" />
            <div style={{ padding: "15px 0", }}>
              <TextField
                id="code"
                name="code"
                //className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                //type={values.showPassword ? 'text' : 'password'}
                label="Access Code"
                helperText={this.state.formfields.code.helperText}
                value={this.state.code}
                onChange={this.handleChange}
                style={{ backgroundColor: 'white', }}
                error={this.state.formfields.code.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="submit"
                        onClick={this.handleSubmit}
                        type="submit"
                      >
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>)
    }
  }
}

export default (ViewView)