import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeView from './views/Home';
import NewView from './views/New';
import ViewView from './views/View';
//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route path="/new" component={NewView} />
          <Route path="/view/:code" component={ViewView} />
          <Route component={HomeView} />
        </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
